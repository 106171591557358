<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-clipboard-list fa-md"></i> <span class="h5"> Cuestionario de satisfacción</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="7">
                            <b-form-group label="Cliente:" class="mb-2">
                                <b-form-input type="text" disabled class="disabled-white" v-model="datosSatisfaccion.cliente"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" class="mb-4">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="text" disabled class="disabled-white" v-model="datosSatisfaccion.fecha"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-table bordered :items="datosSatisfaccion.listaPreguntas" :fields="camposPreguntas" class="table-custom mt-2" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(respuesta)="row">
                                    <b-row>
                                        <b-col :md="row.item.hasFichero == 1 && row.item.urlArchivo !=null ? '6':'12'">
                                            <star-rating :read-only="true" style="display: inline-block;" class="text-center" v-if="row.item.tipoPregunta == 2" :star-size="20" :show-rating="false" v-model="row.item.valoracion"></star-rating>
                                            <template v-if="row.item.tipoPregunta == 1">
                                                <span v-if="row.item.valoracion == 1">SI</span>
                                                <span v-else-if="row.item.valoracion == 2">NO</span>
                                            </template>
                                        </b-col>
                                        <b-col md="6" v-if="row.item.hasFichero == 1 && row.item.urlArchivo !=null">
                                            <b-button variant="dark" @click="descargarDocumento(row.item.urlArchivo)"><i class="fas fa-download mr-1"></i>{{row.item.nombreArchivo}}</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col md="12" class="mt-3">
                            <span class="text-muted h6">Si desea realizar algún comentario adicional, por favor, hágalo a continuación:</span>
                            <hr class="mb-3 mt-1">
                            <b-form-group class="mb-2">
                                <b-form-textarea disabled class="disabled-white" v-model="datosSatisfaccion.observacion" rows="4" max-rows="6"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" class="text-center my-2">
                            <b-button size="md" variant="dark" class=" mr-2" :to="{name: 'Satisfaccion Cliente'}">
                                <i class="fas fa-arrow-left"></i> Volver
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import StarRating from 'vue-star-rating';
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    components: {
        StarRating
    },
    data() {
        return {
            disabled: false,
            datosSatisfaccion: {
                idSatisfaccionCliente: '',
                idCliente: '',
                fecha: '',
                cliente: '',
                observacion: '',
                listaPreguntas: []
            },

            listaPreguntas: [],

            camposPreguntas: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: 'pregunta',
                label: 'Aparto analizado',
                class: 'text-center'
            }, {
                key: 'respuesta',
                label: 'Valoración',
                class: 'text-center'
            }],

        }
    },
    methods: {

        descargarDocumento(url) {
            window.open(url)
        },
        obtenerSatisfaccion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-satisfaccion-cliente", {
                        params: {
                            idCliente: me.datosSatisfaccion.idCliente,
                            idSatisfaccionCliente: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosSatisfaccion.cliente = response.data[0].razonSocial;
                    me.datosSatisfaccion.fecha = response.data[0].fechaCompletado;
                    me.datosSatisfaccion.observacion = response.data[0].observacion;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = true;
                });
        },

        obtenerPreguntasSatisfaccion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-satisfaccion-cliente", {
                        params: {
                            idCliente: me.datosSatisfaccion.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    let data = response.data.filter(x => x.idSatisfaccionCliente == me.$route.params.id);
                    for (let i in data) {
                        me.datosSatisfaccion.listaPreguntas.push({
                            idPreguntaSatisfaccion: data[i].idPreguntaSatisfaccion,
                            pregunta: data[i].pregunta,
                            hasFichero: data[i].hasFichero,
                            tipoPregunta: data[i].tipoPregunta,
                            urlArchivo: data[i].urlArchivo,
                            valoracion: parseInt(data[i].valoracion),
                            nombreArchivo: data[i].nombreArchivo,
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = true;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    mounted() {
            if (this.$store.state.user) {
                let user = this.$store.state.user;
                this.datosSatisfaccion.idCliente = user.uidClient;
                this.obtenerSatisfaccion();
                this.obtenerPreguntasSatisfaccion();
            }
        }
    }
</script>
